import React from 'react';
import AppShell from '../components/app-shell/app-shell';
import PublicShell from '../components/app-shell/public-shell';
import IWantToLearnCalligraphy from '../components/landing-pages/i-want-to-learn-calligraphy';

const structuredData = {
  '@type': 'WebSite',
  '@id': 'https://www.calligraphy.org/#website',
  url: 'https://www.calligraphy.org/',
  name: 'Calligraphy.org',
  about: {
    '@type': 'Organization',
    brand: {
      '@type': 'Brand',
      name: 'Calligraphy.org',
      description:
        'Calligraphy.org is dedicated to online learning. We teach the lettering arts through a series of online classes that feature expert instructors and one-on-one coaching.',
      logo:
        'https://storage.googleapis.com/quiver-four.appspot.com/production%2Fuploads%2Fcalligraphy-org-logo-1.png?GoogleAccessId=firebase-adminsdk-z45et@quiver-four.iam.gserviceaccount.com&Expires=2145916800&Signature=SI6rK3m8JEkuck4Tj%2BO4%2Bo0chEhIwu9gQFELIR2Ja6ScrQTjOltJBOo2bWbNp5m4njFQ0tttaWw7WRdy83usqbrkAloib%2BUYwEH7cv9vwDy%2F1zglG57GYt2vZIc84tTa4z7ggVh2JQPydXMV3G9dHyMfysnSnC2lpVG7nQxXmvM2pR6gC257QkvfTYoRYO8vkTms4Afu6bT8kbBEc3T9Ukb4%2B0%2BoSZZgzTh6P%2BGIZxHP9OWlYI4UC2BQ8aOd0Bu0S4C1tp0OPaE7seeaEHxE3eSF829ktK9ckeKTHSMKe4z9E7O5pedjBYyxCnuT2bhkaE2lDjboDOubxb1eMnWL3w%3D%3D',
      url: 'https://www.calligraphy.org',
    },
    email: 'chris@calligraphy.org',
    founder: {
      '@type': 'Person',
      email: 'melissa@calligraphy.org',
      givenName: 'Melissa',
      familyName: 'Esplin',
    },
    legalName: 'Quiver Inc',
  },
};

export default function() {
  return (
    <AppShell isLandingPage>
      <PublicShell data={structuredData}>
        <IWantToLearnCalligraphy />
      </PublicShell>
    </AppShell>
  );
}
